type MiddlewareMeta =
  | boolean
  | {
      /**
       * Whether to only allow unauthenticated users to access this page.
       *
       * Authenticated users will be redirected to `/` or the route defined in `navigateAuthenticatedTo`
       *
       * @default undefined
       */
      unauthenticatedOnly?: boolean
      /**
       * Where to redirect authenticated users if `unauthenticatedOnly` is set to true
       *
       * @default undefined
       */
      navigateAuthenticatedTo?: string
      /**
       * Where to redirect unauthenticated users if this page is protected
       *
       * @default undefined
       */
      navigateUnauthenticatedTo?: string
    }

declare module '#app' {
  interface PageMeta {
    auth?: MiddlewareMeta
  }
}

declare module 'vue-router' {
  interface RouteMeta {
    auth?: MiddlewareMeta
  }
}

export default defineNuxtRouteMiddleware((to) => {
  const metaAuth =
    typeof to.meta.auth === 'object'
      ? {
          unauthenticatedOnly: true,
          ...to.meta.auth,
        }
      : to.meta.auth

  if (metaAuth === false) {
    return
  }

  const localePath = useLocalePath()
  const authConfig = useRuntimeConfig().public.auth
  const { status } = useAuth()
  const isGuestMode =
    typeof metaAuth === 'object' && metaAuth.unauthenticatedOnly
  // Guest mode happy path 1: Unauthenticated user is allowed to view page
  if (isGuestMode && status.value === 'unauthenticated') {
    return
  }

  if (status.value === 'authenticated') {
    // Guest mode happy path 2: Authenticated user should be directed to another page
    if (isGuestMode) {
      return navigateTo(localePath(metaAuth.navigateAuthenticatedTo ?? '/'))
    }
    return
  }

  // We do not want to block the login page when the local provider is used
  if (authConfig.provider?.type === 'local') {
    const loginRoute: string | undefined = authConfig.provider?.pages?.login
    if (loginRoute && loginRoute === to.path) {
      return
    }
  }

  if (typeof metaAuth === 'object' && metaAuth.navigateUnauthenticatedTo) {
    return navigateTo({
      path: localePath(metaAuth.navigateUnauthenticatedTo),
      query: { redirect: to.path },
    })
  } else {
    return navigateTo(localePath(authConfig.provider.pages.login))
  }
})
